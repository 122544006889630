import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="prestige-classes"></a><h2>Prestige Classes</h2>
    <a id="loremaster"></a><h3>LOREMASTER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/dmg35_gallery/DMG35_PG191_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Hit Die</b>:
d4.</p>
    <a id="loremaster-requirements"></a><h6>Requirements</h6>
    <p className="initial">To qualify to become a loremaster, a character
must fulfill all the following criteria.</p>
    <p><b>Skills</b>: Knowledge (any two) 10 ranks in each.</p>
    <p><b>Feats</b>: Any three metamagic or item creation feats, plus
Skill Focus (Knowledge [any individual Knowledge skill]).</p>
    <p><b>Spells</b>: Able to cast seven different divination spells,
one of which must be 3rd level or higher.</p>
    <h6>Class Skills</h6>
    <p className="initial">The loremaster&#8217;s class skills (and the key
ability for each skill) are <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#appraise">Appraise</a>
(Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#concentration">Concentration</a> (Con), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#craft">Craft</a>
(alchemy) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#decipher-script">Decipher Script</a> (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#gather-information">Gather Information</a> (Cha),
      <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#handle-animal">Handle Animal</a> (Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#heal">Heal</a>
(Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (all skills taken
individually) (Int), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#perform">Perform</a>
(Cha), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#profession">Profession</a> (Wis), <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#speak-language">Speak
Language</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#spellcraft">Spellcraft</a> (Int), and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#use-magic-device">Use Magic Device</a> (Cha). </p>
    <p><b>Skill Points at Each Level</b>: 4 + Int modifier.</p>
    <a id="table-the-loremaster"></a><p><b>Table: The Loremaster</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "7%"
          }}>Level</th>
          <th style={{
            "width": "12%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "7%"
          }}>Fort
Save</th>
          <th style={{
            "width": "7%"
          }}>Ref
Save</th>
          <th style={{
            "width": "7%"
          }}>Will
Save</th>
          <th style={{
            "width": "16%"
          }}>Special</th>
          <th style={{
            "width": "44%"
          }}>Spells per Day</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+0</td>
          <td>+0</td>
          <td>+2</td>
          <td>Secret</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+0</td>
          <td>+0</td>
          <td>+3</td>
          <td>Lore</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+1</td>
          <td>+1</td>
          <td>+1</td>
          <td>+3</td>
          <td>Secret</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Bonus language</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+2</td>
          <td>+1</td>
          <td>+1</td>
          <td>+4</td>
          <td>Secret</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Greater lore</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+3</td>
          <td>+2</td>
          <td>+2</td>
          <td>+5</td>
          <td>Secret</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+4</td>
          <td>+2</td>
          <td>+2</td>
          <td>+6</td>
          <td>Bonus language</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+4</td>
          <td>+3</td>
          <td>+3</td>
          <td>+6</td>
          <td>Secret</td>
          <td>+1 level of existing class</td>
        </tr>
        <tr>
          <td className="last-row">10th</td>
          <td className="last-row">+5</td>
          <td className="last-row">+3</td>
          <td className="last-row">+3</td>
          <td className="last-row">+7</td>
          <td className="last-row">True lore</td>
          <td className="last-row">+1 level of existing class</td>
        </tr>
      </tbody>
    </table>
    <a id="loremaster-class-features"></a><h6>Class Features</h6>
    <p className="initial">All of the following are Class Features of the
loremaster prestige class.</p>
    <p><b>Weapon and Armor Proficiency</b>: Loremasters gain no
proficiency with any weapon or armor.</p>
    <p><b>Spells per Day/Spells Known</b>: When a new loremaster
level is gained, the character gains new spells per day (and spells
known, if applicable) as if she had also gained a level in a
spellcasting class she belonged to before she added the prestige class.
She does not, however, gain any other benefit a character of that class
would have gained. This essentially means that she adds the level of
loremaster to the level of some other spellcasting class the character
has, then determines spells per day, spells known, and caster level
accordingly.</p>
    <a id="loremaster-secret"></a><p><b>Secret</b>: At 1st level and every two levels higher than
1st (3rd, 5th, 7th, and 9th), the loremaster chooses one secret from
the table below. Her level plus Intelligence modifier determines the
total number of secrets she can choose. She can&#8217;t choose the same
secret twice.</p>
    <a id="loremaster-lore"></a><p><b>Lore</b>: At 2nd level, a loremaster gains the ability to
know legends or information regarding various topics, just as a bard
can with bardic knowledge. The loremaster adds her level and her
Intelligence modifier to the lore check, which functions otherwise
exactly like a bardic knowledge check.</p>
    <p><b>Bonus Languages</b>: A loremaster can choose any new
language at 4th and 8th level.</p>
    <a id="loremaster-greater-lore"></a><p><b>Greater Lore (Ex)</b>: At 6th level, a loremaster gains the
ability to understand magic items, as with the identify spell.</p>
    <a id="loremaster-true-lore"></a><p><b>True Lore (Ex)</b>: At 10th level, once per day a
loremaster can use her knowledge to gain the effect of a legend lore
spell or an analyze dweomer spell.</p>
    <a id="table-loremaster-secrets"></a><p><b>table: Loremaster Secrets:</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "100px"
          }}>Level +
Int Modifier</th>
          <th style={{
            "width": "200px"
          }}>Secret</th>
          <th style={{
            "width": "200px"
          }}>Effect</th>
        </tr>
        <tr className="odd-row">
          <td>1</td>
          <td>Instant mastery</td>
          <td>4 ranks of a skill in which the character has no ranks
&nbsp;</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Secret health</td>
          <td>+3 hit points</td>
        </tr>
        <tr className="odd-row">
          <td>3</td>
          <td>Secrets of inner strength</td>
          <td>+2 bonus on Will saves</td>
        </tr>
        <tr>
          <td>4</td>
          <td>The lore of true stamina</td>
          <td>+2 bonus on Fortitude saves</td>
        </tr>
        <tr className="odd-row">
          <td>5</td>
          <td>Secret knowledge of avoidance</td>
          <td>+2 bonus on Reflex saves</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Weapon trick</td>
          <td>+1 bonus on attack rolls</td>
        </tr>
        <tr className="odd-row">
          <td>7</td>
          <td>Dodge trick</td>
          <td>+1 dodge bonus to AC</td>
        </tr>
        <tr>
          <td>8</td>
          <td>Applicable knowledge</td>
          <td>Any one feat</td>
        </tr>
        <tr className="odd-row">
          <td>9</td>
          <td>Newfound arcana</td>
          <td>1 bonus 1st-level spell<sup>*</sup></td>
        </tr>
        <tr>
          <td className="last-row">10</td>
          <td className="last-row">More newfound arcana</td>
          <td className="last-row">1 bonus 2nd-level spell<sup>*</sup></td>
        </tr>
        <tr>
          <td colSpan="3">* <i>As if gained through having a high
ability score.</i></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      